<template>
    <div>
        <app-heading-3 class="mb-1">Add Payment Method</app-heading-3>
        <b-overlay
            :show="defaultLoading"
            rounded
            opacity="0.6"
            spinner-variant="primary"
            >
            <div class="bg-white p-24" v-show="!newPayment">
                <div class=" mt-2 d-flex justify-content-between mb-3">
                    <div class="d-flex">
                        <img :src="getBrandIcon(cardData.brand)" alt="" height="25" width="45">
                        <app-heading-3 class="ml-1">....{{cardData.last4}}( expire {{cardData.exp_month}}/{{ cardData.exp_year }})</app-heading-3>
                        <b-badge variant="light-secondary" class="ml-1">Default</b-badge>
                    </div>
                    <div @click="()=>newPayment=true">
                        <feather-icon icon="Edit3Icon" class="text-primary cursor-pointer" size="18"></feather-icon>
                    </div>
                </div>
                <div class="d-block d-md-flex" >
                    <app-simple-button variant="outline-primary" class="mr-1 mb-1" @click="$emit('prev')">Back</app-simple-button>
                    <app-simple-button variant="primary" :loading="subscribeLoading" @click="$emit('next',cardData.payment_method_id)">Pay Now</app-simple-button>
                </div>
            </div>
            <div class="bg-white p-24" v-show="newPayment">
                <b-alert class="px-2 py-1" v-model="error.length" variant="danger">
                {{ error }}
                </b-alert>
                <div class="row ">
                    <div class="col-12 mb-1">
                        <app-label>Card Number</app-label>
                        <div class="input-field">
                            <div ref="cardNumber"></div>

                        </div>
                    </div>
                </div>
                <div class="row ">
                    <div class="col-12 col-lg-4 ">
                        <app-text-input label="Name On Card" v-model="cardName" placeholder="John Doe"/>
                    </div>
                    <div class="col-6 col-lg-4 pl-md-0 ">
                        <app-label>Expiration Date</app-label>
                        <div class="input-field">
                            <div ref="expryDate"></div>
                        </div>
                        <!-- <app-text-input label="Expiration Date" v-model="payment_method_details.card_exp" placeholder="MM/YY"/> -->
                    </div>
                    <div class="col-6 col-lg-4 pl-md-0">
                        <app-label>CVC</app-label>
                        <div class="input-field">
                            <div ref="cvc"></div>
                        </div>
                        <!-- <app-text-input label="CVC" v-model="payment_method_details.card_cvc" placeholder="455"/> -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="text-small mb-2">By providing your card information, you allow Collabdocs to charge your card for future payments in accordance with their terms.</div>
                    </div>
                </div>
                <div class="d-block d-md-flex">
                    <app-simple-button variant="outline-primary" class="mr-1 mb-1" @click="$emit('prev')">Back</app-simple-button>
                    <app-simple-button variant="primary" :loading="subscribeLoading" @click="createPayment()">Pay Now</app-simple-button>
                </div>
            </div>
        </b-overlay>
    </div>

</template>
<script>
import {BFormCheckbox, BAlert, BBadge, BOverlay} from 'bootstrap-vue'
import AppSimpleButton from '@/components/app-components/AppSimpleButton.vue'
import AppLabel from '@/components/app-components/AppLabel.vue'
import AppTextInput from "@/components/app-components/AppTextInput.vue"
import { loadStripe } from '@stripe/stripe-js'
import AppHeading3 from "@/components/app-components/AppHeading3.vue"
import {get_payment_method, delete_payment_method, } from '@/apis/others/subscriptions'

export default{
    components:{
        BFormCheckbox,
        AppSimpleButton,
        AppTextInput,
        AppHeading3,
        AppLabel,
        BAlert,
        BBadge
    },
    data(){
        return{
            style:{
                base: {
                iconColor: '#c4f0ff',
                color: '#646464',
                fontWeight: '500',
                fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
                fontSize: '16px',
                fontSmoothing: 'antialiased',
                ':-webkit-autofill': {
                    color: '#000'
                },
                '::placeholder': {
                    color: '#b9b9c3',
                    fontSize:'14px'
                },
                invalid: {
                    iconColor: '#FFC7EE',
                    color: '#FFC7EE'
                }
                },
            },
            stripeInstance:'',
            stripeElement:'',
            cardNumber:'',
            cardExpiry:'',
            cardCvc:'',
            cardName:'',
            error:'',
            loading:false,
            cardData:{
                payment_method_id:'',
                last4:'',
                exp_month:'',
                exp_year:'',
                brand:'',
            },
            newPayment:false,
            defaultLoading:false,
            subscribeLoading:false
        }
    },
    mounted() {
        this.createStripeInstence()
        this.getPaymentMethod()
    },
    methods: {
        async createStripeInstence(){
            this.stripeInstance = await loadStripe(process.env.VUE_APP_STRIPE_SECRET_KEY);
            this.stripeElement = this.stripeInstance.elements();
            this.cardNumber = this.stripeElement.create('cardNumber', { style: this.style,})
            this.cardNumber.mount(this.$refs.cardNumber)
            this.cardExpiry = this.stripeElement.create('cardExpiry',{ style: this.style})
            this.cardExpiry.mount(this.$refs.expryDate)
            this.cardCvc = this.stripeElement.create('cardCvc',{ style: this.style})
            this.cardCvc.mount(this.$refs.cvc)
        },
        async createPayment(){
            this.loading=true;
            const { error, paymentMethod } = await this.stripeInstance.createPaymentMethod({
                type: 'card',
                card: this.cardNumber,
                billing_details: {
                    name:this.cardName
                }
            })
            if (error) {
                this.error=error.message
                this.loading=false;
            } else {
                this.$emit('next', paymentMethod.id)
                this.loading=false;
            }
        },
        getPaymentMethod(){
            this.defaultLoading=true;
            this.newPayment = true;
            get_payment_method().then(resp=>{
                this.defaultLoading=false;
                this.newPayment = false;
                this.cardData.payment_method_id=resp.id;
                this.cardData.last4=resp.card.last4;
                this.cardData.exp_month=resp.card.exp_month;
                this.cardData.exp_year=resp.card.exp_year;
                this.cardData.brand=resp.card.brand;
            }).catch(err=>{
                this.defaultLoading=false;
                // this.$bvToast.toast(err.message, {
                //         title: err.error,
                //         variant: 'danger',
                //         solid: true,
                //     }) 
            })
        },
        getBrandIcon(name){
            if(name=='visa'){
                return require('@/assets/images/app-icons/visa.png')
            }else if(name=='master'){
                return require('@/assets/images/app-icons/master.png')
            }else if(name=='american-express'){
                return require('@/assets/images/app-icons/american-express.png')
            }else{
                return require('@/assets/images/app-icons/credit-card.png')
            }
        },
      
    },
}
</script>
<style scoped>
.p-24{
    padding: 24px;
    border-radius: 6px;
}
.sup{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
    position: relative;
    bottom: 15px;
}
.price-text{
    font-size: 36px;
    font-weight: 600;
    line-height: 52px;
}
.text-simple{
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #475569;
}
.main-text{
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: #0F172A;
}
.text-small{
    font-size: 15px;
    line-height: 22px;
    font-weight: 400;
    color: #4B465C;
}
.input-field{
    border:1px solid #DBDADE;
    border-radius: 6px;
    padding: 9px 14px;
}
</style>